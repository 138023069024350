<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
    style="z-index: 999 !important;"
  >
    <template #button-content>
      <feather-icon
        :badge="countNotification || 0"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
      <b-spinner v-if="isLoading" small />
      <small v-if="isLoading"><i> Loading notification... </i></small>
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <b-badge pill variant="light-primary">
          {{ countNotification }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <!-- Account Notification -->
      <b-link v-for="(notification, index) in notifications" :key="index">
        <b-media
          :style="
            notification.is_read === true
              ? 'background-color: white !important'
              : 'background-color: whitesmoke !important'
          "
          @click="onTapNotification(index)"
        >
          <template #aside>
            <!-- :text="
                Array.from(notification.title)[0] +
                Array.from(notification.title)[1]
              " -->
            <b-avatar size="32" src="" :variant="notification.type">
              <feather-icon
                size="25"
                :icon="
                  ['TBBM', 'Hulu Migas', 'FORM_TUG_BOAT'].includes(
                    notification.form_type
                  )
                    ? 'AnchorIcon'
                    : 'TruckIcon'
                "
              />
            </b-avatar>
          </template>

          <div v-if="notification.tag === 'land_route'">
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.title }}{{ notification.action }}
              </span>
              <code>{{ moment(notification.created_at).fromNow() }}</code>
            </p>
            <small class="notification-text"
              >{{ notification.subtitle }}
              <b-badge variant="primary">
                {{ notification.form_type }}
              </b-badge>
              &nbsp;
              <b-badge v-if="notification.group" variant="info">
                {{ notification.group }}
              </b-badge>
              &nbsp;
              <b-badge variant="info">
                {{ notification.desc2 }}
              </b-badge>
              &nbsp;
              <b-badge variant="warning" @click="copyText(notification.desc1)">
                {{ notification.desc1 }}
              </b-badge>
              &nbsp;
              <b-badge v-if="notification.company" variant="secondary">
                {{ notification.company }}
              </b-badge>
            </small>
          </div>
          <div v-else>
            <p class="media-heading">
              <span class="font-weight-bolder">
                {{ notification.title }} {{ notification.action }}
              </span>
              <code>{{
                moment(notification.desc3).format('DD/MM/YYYY h:m A')
              }}</code>
            </p>
            <small class="notification-text"
              >{{ notification.subtitle }}
              <b-badge variant="primary">
                {{
                  notification.form_type === 'FORM_TUG_BOAT'
                    ? 'TUG BOAT'
                    : notification.form_type
                }}
              </b-badge>

              <b-badge
                variant="primary"
                v-if="notification.pemilik"
                class="ml-1"
              >
                {{ notification.pemilik }}
              </b-badge>
              &nbsp;
              <b-badge variant="info">
                {{ notification.desc2 }}
              </b-badge>
              &nbsp;
              <b-badge variant="warning" @click="copyText(notification.desc1)">
                {{ notification.desc1 }}
              </b-badge>
              &nbsp;
              <b-badge v-if="notification.company" variant="secondary">
                {{ notification.company }}
              </b-badge>
            </small>
          </div>
        </b-media>
      </b-link>
    </vue-perfect-scrollbar>

    <!-- Cart Footer -->
    <li class="dropdown-menu-footer">
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="readAllNotification()"
        >Read all notifications</b-button
      >
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BBadge,
  BMedia,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import firebase from 'firebase/app'
import 'firebase/messaging'
import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import Pusher from 'pusher-js'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'
import useJwt from '@/auth/jwt/useJwt'
import { nextTick } from 'process'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    ToastificationContent
  },
  directives: {
    Ripple
  },
  setup() {
    /* eslint-disable global-require */
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false
    }
    const { lookWatchException, anIncomingNotificationRiver, watchApproval } =
      useAppConfig()

    let countNotification = 0
    return {
      perfectScrollbarSettings,
      countNotification,
      lookWatchException,
      anIncomingNotificationRiver,
      watchApproval
    }
  },
  data() {
    return {
      isLoading: false,
      registrationToken: '',
      notifications: [],
      errorRequest: false,
      timeoutDebounce: null,
      roleName: JSON.parse(localStorage.getItem('userData'))?.role ?? '',
      groupName: JSON.parse(localStorage.getItem('userData')).group?.name ?? ''
    }
  },
  watch: {
    lookWatchException(params) {
      /* ERROR HANDLING NEWS */
      const error = params
      try {
        if (error.includes(['expired'])) {
          this.$swal({
            title: '7 Hari Berlalu',
            html: '<strong>Jika pada hari ke 7 tidak membuka Website</strong>, maka akan terjadi force to <strong>logout</strong> (<i>expired login 7 days</i>).',
            // eslint-disable-next-line global-require
            imageUrl: require('@/assets/images/pages/error.svg'),
            imageAlt: 'system_update',
            confirmButtonText: 'CLOSE',
            showCancelButton: false,
            customClass: {
              confirmButton: 'btn btn-success',
              cancelButton: 'btn btn-outline-danger ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.$forceUpdate()
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => {
                useJwt.errorHandling(null)
                localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                localStorage.removeItem(
                  useJwt.jwtConfig.storageRefreshTokenKeyName
                )
                localStorage.removeItem('userData')
                this.$router.push({ name: 'auth-login' })
                // this.$router.go(-1)
              }, 300)
            }
          })
        }
      } catch (error) {
        const [firstKey] = Object.keys(params) // by keys
        const [firstValue] = Object.values(params)

        // this.errorMatchValue = params.value || null // by values
        // this.errorPath = firstKey /* v-model validation */
        // this.errorValue = firstValue

        this.$swal({
          title: 'Error!',
          text: `${JSON.stringify(firstValue)}`,
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
      }
    }
  },
  async created() {
    this.isLoading = true
    await this.fetch()
    await this.listeningFirebase()
    this.$store.commit('app/UPDATE_ROLE_NAME', this.roleName)
    console.log(
      'this.$store.state.app.roleName::',
      this.$store.state.app.roleName
    )
    // this.fetchPusher()
  },
  methods: {
    moment,
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    timeSince(date) {
      const seconds = Math.floor(
        (moment().format('YYYY/MM/DD HH:mm:ss') -
          moment(date).format('YYYY/MM/DD HH:mm:ss')) /
          1000
      )

      let interval = seconds / 31536000

      if (interval > 1) {
        return `${Math.floor(interval)} years`
      }
      interval = seconds / 2592000
      if (interval > 1) {
        return `${Math.floor(interval)} months`
      }
      interval = seconds / 86400
      if (interval > 1) {
        return `${Math.floor(interval)} days`
      }
      interval = seconds / 3600
      if (interval > 1) {
        return `${Math.floor(interval)} hours`
      }
      interval = seconds / 60
      if (interval > 1) {
        return `${Math.floor(interval)} minutes`
      }
      return `${Math.floor(seconds)} seconds`
    },
    notificationLandRoute() {
      const keyword = `created_at=${moment().format('Y-MM-D')}`
      useJwt.http.get('notification', { params: { keyword } }).then((res) => {
        // console.log('object', res.data)
        const resData = res.data.notifications.map((data) => ({
          ...data,
          desc1: data.driver_name,
          desc2: data.plat_no_pol,
          company: data.driver_from_company,
          title: data.user?.full_name ?? 'NA',
          // group: data.group?.name ?? '' // null properties
          group:
            data.group?.name === 'SLB' ? 'Hulu Migas' : data.group?.name ?? ''
        }))
        // console.log(resData)
        this.notifications = resData
        this.countNotification = this.notifications.filter(
          (data) => data.is_read === false
        ).length
      })
    },
    notificationRiverRoute() {
      const keyword = `created_at=${moment().format('Y-MM-D')}`
      useJwt.http
        .get('notification_river', { params: { keyword } })
        .then((res) => {
          // console.log('object', res.data)
          const resData = res.data.notification_rivers.map((data) => ({
            ...data,
            desc1: data.no_pkk,
            desc2: data.posisi,
            desc3: data.time,
            desc4: data.posisi,
            company: data.company,
            title: data.boat_name,
            group: data.group?.name ?? '' // null properties
          }))
          // console.log(resData)
          if (this.$can('TBBM', '') || this.$can('TBBM JETTY 3A', '')) {
            if(this.$can('TBBM JETTY 3A', '')){
              this.notifications = resData.filter(
                (data) => data.pemilik != 'Hulu Migas' && data.desc2.includes('JETTY 3A')
              )
            } else {
              this.notifications = resData.filter(
                (data) => data.pemilik != 'Hulu Migas'
              )
            }
          }
          if (this.$can('Hulu Migas', '')) {
            this.notifications = resData.filter(
              (data) => data.pemilik == 'Hulu Migas'
            )
          }
          if (this.$can('SAL', '')) {
            this.notifications = resData
          }
          // [new-1]
          // console.log(resData)
          this.countNotification = this.notifications.filter(
            (data) => data.is_read === false
          ).length
          this.isLoading = false
        })
    },
    notificationLandRiverRoute() {
      const keyword = `created_at=${moment().format('Y-MM-D')}`
      useJwt.http
        .get('vw_notification', { params: { keyword } })
        .then((res) => {
          // console.log('object', res.data)
          const resData = res.data.vw_notifications.map((data) =>
            data.tag === 'land_route'
              ? {
                  ...data,
                  desc1: data.desc2,
                  desc2: data.desc1,

                  title: data.user?.full_name ?? 'NA',
                  group:
                    data.group?.name === 'SLB'
                      ? 'Hulu Migas'
                      : data.group?.name ?? ''
                }
              : {
                  ...data,
                  desc1: data.desc1,
                  desc2: data.desc4,
                  desc3: data.desc3,
                  desc4: data.desc4,

                  title: data.desc2 ?? 'BA',
                  group: data.pemilik
                }
          )
          // console.log(resData)
          this.notifications = resData
          this.countNotification = this.notifications.filter(
            (data) => data.is_read === false
          ).length
          this.isLoading = false
        })
    },
    fetch() {
      // this.notificationLandRoute()
      if (
        this.roleName === 'viewer' ||
        this.roleName === 'host' ||
        this.roleName === 'security' ||
        this.roleName === 'jetty man'
      ) {
        this.notificationLandRiverRoute()
      } else {

        if (
          ((this.groupName === 'TEM' || this.groupName === 'PATRA') &&
            this.$can('manage', 'special')) ||
          this.$can('approvement', '')
        ) {
          /* 1. load by pemilik */
          console.log('i am admin of special', this.groupName)
          console.log('show::notificationLandRiverRoute ')
          this.notificationLandRiverRoute()
        } else {
          /* is sungai ?  */
          if (
            this.$can('Hulu Migas', '') ||
            this.$can('TBBM', '') ||
            this.$can('TBBM JETTY 3A', '') ||
            this.$can('SAL', '')
          ) {
            /*  1. load by user_id
                2. load by pemilik when permission is SAL */
            if (this.$can('SAL', '') && this.$can('manage', 'form3')) {
              console.log('i am admin of sal form3', this.groupName)
              console.log('show::notificationLandRiverRoute ')
              this.notificationLandRiverRoute()
            } else {
              /* possible 2 akses darat dan sungai */
              if(this.$can('Hulu Migas', '') || this.$can('TBBM', '') || this.$can('TBBM JETTY 3A', '') && (this.$can('manage', 'form1') || this.$can('manage', 'form2') || this.$can('manage', 'form3'))){
                console.log('show::notificationLandRoute ')
                this.notificationLandRiverRoute()
              } else {
                console.log('show::notificationRiverRoute ')
                this.notificationRiverRoute() // currently loaded all between  time
              }
            }

            /* is not sungai */
          } else {
            console.log('show::notificationLandRoute ')
            if (this.$can('manage', 'qrcode')) {
              this.notificationLandRiverRoute()
            } else {
              this.notificationLandRoute()
            }
          }
        }
      }
    },
    setData() {},
    listeningFirebase() {
      try {
        firebase
          .messaging()
          .requestPermission()
          .then(() => {
            this.errorRequest = false
            console.log('Notification permission granted')
            firebase
              .messaging()
              .getToken()
              .then((token) => {
                this.$store.commit('app/UPDATE_TOKEN_NOTIFICATION', token)
                this.registrationToken = token
                console.log('registrationToken: ', token)
                // this.fetch()

                let admin =
                  this.$can('manage', 'all') /* Host */ ||
                  this.$can('manage', 'qrcode') /* Security */ ||
                  this.$can('manage', 'special') /* Admin Agent */ ||
                  this.$can('approvement', '') /* Admin PIL */

                let landAccess =
                  /* Published form */
                  this.$can('manage', 'form1') ||
                  this.$can('manage', 'form2') ||
                  this.$can('manage', 'form3')
                let riverAccess =
                  /* Published form */
                  this.$can('SAL', '') ||
                  this.$can('TBBM', '') ||
                  this.$can('TBBM JETTY 3A', '') ||
                  this.$can('Hulu Migas', '')

                if (admin || landAccess) {
                  console.info(
                    'subscribe to topic ::',
                    process.env.VUE_APP_TOPIC_LAND
                  )
                  this.subscribeToTopic(
                    token,
                    `${process.env.VUE_APP_TOPIC_LAND}`
                  ) /* sal|land */
                }

                if (admin || riverAccess) {
                  console.info(
                    'subscribe to topic ::',
                    process.env.VUE_APP_TOPIC_RIVER
                  )
                  this.subscribeToTopic(
                    token,
                    `${process.env.VUE_APP_TOPIC_RIVER}`
                  ) /* river */
                }
              })
          })
          .catch((err) => {
            console.log('Unable to get token ', err)
            this.errorRequest = true
            this.$parent.showAlert()
          })
      } catch (e) {
        console.log(e)
      }
    },
    async subscribeToTopic(token, topic) {
      await useJwt.http
        .post(`notification/subscribe/topic/${topic}`, {
          registrationToken: token
        })
        .then((success) => {
          console.log(success.data.message)
          this.errorRequest = false
          this.receiveMessage()
        })
        .catch((error) => {
          this.errorRequest = true
          console.log('error subscribe to topic', error)
          this.$parent.showAlert()
        })
    },
    async receiveMessage() {
      try {
        firebase.messaging().onMessage((payload) => {
          this.fetch()
          console.log('payload here', payload)
          /* action clock in out published */
          const action = payload.data.action
          const tag = payload.data.tag
          const fcmMessageId = payload.fcmMessageId
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Notification',
                icon: 'InfoIcon',
                text: `${payload.notification.title}, ${payload.notification.body}`,
                varianty: 'primary'
              }
            },
            {
              position: 'top-right',
              timeout: 4000
            }
          )
          if (action.includes('Clock')) {
            this.$store.commit('app/UPDATE_WATCH_NOTIFICATION_CLOCK_IN', '')
          } else {
            this.$store.commit('app/UPDATE_WATCH_NOTIFICATION', '')
          }

          console.log('tag notification::', tag)
          if (tag === 'river_route') {
            this.anIncomingNotificationRiver = payload
          }

          // khusus approval limit
          if (action.includes(' ( ✓ Approved )')) {
            console.log('Approved::')
            this.watchApproval = fcmMessageId
            console.log('Approved::', this.watchApproval)
          }
          if (action.includes(' ( ✓ Clock OUT )')) {
            console.log('Clock OUT::')
            this.watchApproval = fcmMessageId
            console.log('Clock OUT::', this.watchApproval)
          }
          if (action.includes(' ( 𝕩 Cancels Approved )')) {
            console.log('Cancels Approved::')
            this.watchApproval = fcmMessageId
            console.log('Cancels Approved::', this.watchApproval)
          }
        })
      } catch (e) {
        console.log(e)
        this.errorRequest = true
        this.$parent.showAlert()
      }
    },
    onTapNotification(index) {
      if (this.notifications[index].is_read === false) {
        this.notifications[index].is_read = true
        if (this.countNotification !== 0) {
          this.countNotification -= 1
        }
        if (this.notifications[index].tag === 'land_route') {
          useJwt.http.put(`notification/${this.notifications[index].id}`, '')
        } else {
          useJwt.http.put(
            `notification_river/${this.notifications[index].id}`,
            ''
          )
        }
      }
    },
    async readAllNotification() {
      let isTherePutAction = false
      await this.notifications
        .filter((data) => data.is_read === false)
        .map((data) => {
          data.is_read = true
          if (
            (this.groupName === 'TEM' || this.groupName === 'PATRA') &&
            this.$can('manage', 'special')
          ) {
            console.log('i am admin of ', this.groupName)
            useJwt.http.put(`notification_river/${data.id}`, '')
            useJwt.http.put(`notification/${data.id}`, '')
            isTherePutAction = true
          } else {
            if (this.$can('Hulu Migas', '') || this.$can('TBBM', '') || this.$can('TBBM JETTY 3A', '')) {
              // this.notificationRiverRoute()
              useJwt.http.put(`notification_river/${data.id}`, '')
              if(this.$can('manage', 'form1') || this.$can('manage', 'form2') || this.$can('manage', 'form3')) {
                useJwt.http.put(`notification/${data.id}`, '')
                isTherePutAction = true
              }
            } else {
              useJwt.http.put(`notification/${data.id}`, '')
              isTherePutAction = true
            }
          }
        })
      this.countNotification = 0
      if(isTherePutAction == true){
        this.fetch()
      }
    }
  }
}
</script>

<style></style>
